.raise-research-modal-container {
  margin: 10px;

  .raise-research-modal-textarea {
    width: 100%;
    margin: 10px;
    padding: 5px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
  }
}
