.upload-new-case-nlock {
  margin: 6px 3px 55px 1px;
  .upload-info {
    padding: 0px;
    padding-left: 20px;
    font-size: 12px;
    color: red;
  }
  .file-upload-case {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .file-name-case {
      font-size: 12px;
      color: rebeccapurple;
      font-weight: 600;
    }
  }
  .spinner-block{
    width: 18px;
    height: 18px;
  }
}
