.select-container-bucketDetail {
    width: 200px;
  }
  
  .expand-link {
    cursor: pointer;
  }
  .expand-link i {
    top: 6px !important;
    position: absolute;
    left: 8px;
  }
  .expand-link.active i {
    transform: rotate(180deg);
    top: -3px !important;
  }
  
  .scroll-top-icon {
    position: fixed;
    right: 5px;
    bottom: 20px;
    z-index: 2000;
  }
  .active-doc {
    background: red !important;
  }
  
  .border-red {
    border-color: red !important;
  }
  
  .download-document-btn {
    background-color: white;
    color: #40aefd;
    padding: 1px;
    margin-left: 5px;
    margin-right: 5px;
    border: 0;
    max-height: 100%;
  
    a {
      background-color: white !important;
      color: #40aefd !important;
    }
  }
  
  .scrollable {
    max-height: 100vh;
    overflow: scroll;
  }
  
  .spinner-block{
    width: 18px;
    height: 18px;
  }

.timer-section {
  padding-top: 45px;
}
