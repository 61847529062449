.gapcheck-circle {
  position: relative;
  top: 5px;
}
.gap-circle {
  position: absolute;
  top: 24px;
  font-size: 9px;
  left: 7px;
}
.gap-line {
  position: absolute;
  left: 11px;
  top: 29px;
  height: 59px;
  border-left: 1px solid gray;
}
.gap-template {
  min-height: 139px;
  background: white;
  position: absolute;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 8px;
  top: 87px;
  left: -48px;
  font-weight: 600;
  font-size: 12px;
  min-width: 118px;
  text-align: center;
}

.arrow-circle {
  border: 1px solid gray;
  border-radius: 50%;
  padding: 2px;
}

.gap-circle-up {
  top: -4px;
}
.gap-line-up {
  top: -59px;
}
.gap-template-up {
  top: -197px;
}

.gap-template-green {
  border-color: #0ec821;
  color: #0ec821;
}
.gap-template-yellow {
  border-color: #ff9720;
  color: #ff9720;
}
.gap-template-pink {
  border-color: #d890f2;
  color: #d890f2;
}

.gap-line-green {
  border-left-color: #0ec821;
}
.gap-line-yellow {
  border-left-color: #ff9720;
}
.gap-line-pink {
  border-left-color: #d890f2;
}
.arrow-circle-green {
  border-color: #0ec821;
  color: #0ec821;
}
.arrow-circle-yellow {
  border-color: #ff9720;
  color: #ff9720;
}
.arrow-circle-pink {
  border-color: #d890f2;
  color: #d890f2;
}
.circle-green {
  color: #0ec821;
}
.circle-yellow {
  color: #ff9720;
}
.circle-pink {
  color: #d890f2;
}

.gap-type {
  color: grey;
  font-weight: 400;
}
