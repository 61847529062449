.gapIdentified{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.crc-check{
    margin-top: 1rem;
}
.block{
    margin-bottom: 1rem;
}
