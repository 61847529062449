.modal-container {
  max-height: 80vh;

  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .docs-name-list {
    max-height: 60vh;
    overflow-x: scroll;
  }
}
