.upload-csis-block {
  padding: 13px 2px;

  .csis-type {
    align-items: center;
    .plus-icon {
      font-size: 24px;
    }
  }
  .csis-file-input {
    margin: 19px 5px;
  }
}
