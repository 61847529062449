.no-data {
  text-align: center;
  margin: 60px;
  font-size: 29px;
  color: red;
}

.pointer {
  cursor: pointer;
}
.table-block {
  margin-top: 11px;
}
.loading-block {
  text-align: center;
  margin-top: 10px;
}
.doc-container-block {
  padding: 12px;
}

.check-close-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .check-close-btn {
    position: relative;
    left: 45%;
  }
}

.row-disabled .input-group-text {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  color: #888 !important;
}
.row-disabled .input-group-text .insuff {
  border: 1px solid red !important;
  color: #888 !important;
}

.row-disabled,
.row-disabled:hover,
a.row-disabled:hover {
  color: #888 !important;
  border-color: #888;
}

.row-disabled.insuff,
.row-disabled:hover.insuff {
  color: #888 !important;
  border-color: red !important;
}

.row-disabled.text-muted,
.row-disabled.text-primary {
  color: #888 !important;
}
.row-disabled.text-muted.insuff,
.row-disabled.text-primary.insuff {
  color: red !important;
}

.row-disabled .input-group {
  cursor: not-allowed;
}
.row-disabled select:disabled,
.row-disabled input:read-only {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  cursor: not-allowed;
  color: #ccc !important;
}

.row-disabled select:disabled .insuff,
.row-disabled input:read-only .insuff {
  border: 1px solid red !important;
  cursor: not-allowed;
  color: #ccc !important;
}
.row-disabled input:read-only,
.row-disabled select:disabled,
.row-disabled textarea:disabled {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  color: #888 !important;
  cursor: not-allowed;
}
.row-disabled input:read-only .insuff,
.row-disabled select:disabled .insuff,
.row-disabled textarea:disabled .insuff {
  border: 1px solid red !important;
  color: #888 !important;
  cursor: not-allowed;
}

.row-disabled::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #888 !important;
  opacity: 1; /* Firefox */
}
.row-disabled:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #888 !important;
}

.row-disabled::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #888 !important;
}
.card-body {
  padding: 10px;
}

.second-tenure-addition-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  button {
    margin-right: 10px;
  }
}

.border-submit {
  border-color: green !important;
}

.border-insuff {
  border-color: red !important;
}
.border-disable {
  border-color: #888 !important;
}

.text-insuff {
  color: red !important;
}

.text-submit {
  color: green !important;
}

.text-disable {
  color: #888 !important;
}
