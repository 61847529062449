.pg-viewer-wrapper {
  .pg-viewer {
    div[style] {
      width: auto !important;

      height: 100% !important;
    }
    .photo-viewer-container {
      img[style] {
        width: auto !important;
        height: 100% !important;
      }
    }
  }
}

.document-section {
  display: flex;
  flex-direction: column;
  padding: 4px !important;
  margin-right: 25px;
  margin-top: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 70px !important;
  // max-height: 90vh;
  height: 100vh;
  .document-view {
    overflow: scroll;
    height: 100%;
  }
}

.labels-section {
  margin-right: 25px;

  max-height: 178px;
  padding: 4px;
  overflow: scroll;
  li {
    height: 30px;
  }
}
