.doc-container {
  margin-bottom: 10px;
}
.doc-block-section {
  margin-top: 10px;
  align-items: center;
  .doc-left {
    width: 80%;
  }
  .doc-right {
    width: 20%;
    text-align: right;
  }
  .doc-block {
    justify-content: space-between;
    align-items: center;
    .doc-section {
      width: 40%;
    }
    .doc-type-block {
      width: 27%;
      .doc-name {
        padding: 9px !important;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .page-number-box {
      width: 13%;
      display: flex;
      align-items: center;
    }
    .expand-dropdown-list {
      width: 3%;
      margin: 0px 4px;
      cursor: pointer;
    }
  }
}
