@import "../../colors";

.cases-count-card {
  .count-container-title {
    color: $primary-blue;
    font-size: 15px;
  }
  .count-box-container {
    display: flex;
    padding: 0;

    .count-box {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      flex: 1;
      cursor: pointer;

      .count-text {
        font-weight: bold;
      }

      .status {
        color: $text-grey;
        text-align: center;
        font-size: 11px;
      }
    }

    .count-box.selected {
      // background: #eee;
      color: $primary-blue;
      font-weight: bold;
      cursor: default;
    }
  }
}
