.hiring-table-container {
  padding: 0;
  height: calc(100% - 102px) !important;
  font-size: 12px !important;

  .header-filler {
    width: 100%;
    height: 80px;
  }

  .hiring-table-header {
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 1px solid #aaa;
    font-weight: bold;
  }

  .hiring-table-body {
    border: 1px solid #aaa;
    height: calc(100% - 155px);
    overflow: scroll;
  }

  .hiring-table-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #aaa;
  }

  .hiring-col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .col-sn {
    width: 5%;
    border-right: 1px solid #aaa;
  }
  .col-source {
    width: 20%;
    border-right: 1px solid #aaa;
  }
  .col-client {
    width: 20%;
    border-right: 1px solid #aaa;
  }
  .col-status {
    width: 15%;
    border-right: 1px solid #aaa;
  }
  .col-locations {
    width: 40%;
    border-right: 1px solid #aaa;
  }
}

.css-p7yp8n {
  max-height: 100% !important;
}
