.otp-block {
  height: 200px;
  .otp-select-box {
    margin-bottom: 50px;
  }
  .otp-input-block {
    margin: 48px 60px;
  }
  .send-otp-button{
    text-align: right;
    margin-bottom: -30px;
  }
}
