.input-checkbox-container {
  display: flex;
  align-items: center;

  input {
    margin-right: 10px;
  }

  label {
    margin: 0;
  }
}
