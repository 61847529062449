.doc-picker-container {
  display: flex;
  flex-grow: 1;

  .document-input-column {
    display: flex;
    justify-content: space-between;
    width: 80%;

    .select-container {
      width: 48%;
    }

    .document-type-container {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .document-tag-input {
      width: 15% !important;
      margin: 0 !important;
    }
  }

  .document-icons-column {
    display: flex;
    width: 20%;
    button {
      flex: 1;
    }
    button {
      flex: 1;
    }
  }
}
