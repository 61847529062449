.block {
  margin-top: 13px;
  .comment {
    color: red;
    margin: 0px 18px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.send-btn {
  width: max-content !important;
}
