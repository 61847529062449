.modal-wrapper {
  background: yellow;
}

.header-block {
  display: flex !important;
  justify-content: space-between !important;
  margin: 12px;
  align-items: center;
  .heading {
    font-size: 16px;
  }
  .cross {
    font-size: 18px;
    cursor: pointer;
    :hover {
      color: #d02d43;
    }
  }
}

.modal-body {
  padding: 0px;
  margin-bottom: 18px;
}

.css-1oc7v0j {
  top: 40px !important;
  bottom: 40px !important;
  display: flex !important;
  height: 100% !important;
  justify-content: space-around !important;
}
.css-krjp51 {
  top: 0px !important;
  display: flex !important;
  height: 100% !important;
  justify-content: space-around !important;
}

.css-1jesbqk {
  top: 0px !important;
  display: flex !important;
  height: 100% !important;
  justify-content: space-around !important;
}
.css-18hudfs{
  max-width: calc(100% - 17px)!important;
  height: 100%!important;
}
.css-79ym8r {
  top: 0px !important;
  display: flex !important;
  height: 100% !important;
  justify-content: space-around !important;
}
