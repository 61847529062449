/* BASIC */

// html {
//   background-color: #56baed;
// }

// body {
//   font-family: "Poppins", sans-serif;
// }

// a {
//   color: #92badd;
//   display: inline-block;
//   text-decoration: none;
//   font-weight: 400;
// }

// h2 {
//   text-align: center;
//   font-size: 16px;
//   font-weight: 600;
//   text-transform: uppercase;
//   display: inline-block;
//   margin: 40px 8px 10px 8px;
//   color: #cccccc;
// }

@import url(https://fonts.googleapis.com/css?family=Roboto:400);
@import url(https://fonts.googleapis.com/css?family=Roboto:700);
body,
html {
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form .register-form {
  display: none;
}
.navbar {
  height: 60px;
}
.navbar .dropdown-menu {
  right: 0;
  left: auto;
}
nav.bg-light {
  background-color: #ededed !important;
}
.navbar-toggler {
  padding: 0.25rem 1.2rem;
}
.navbar-toggler:focus {
  outline: none !important;
}
.sidebar-2020 {
  width: 65px;
  height: inherit;
  float: left;
  background-color: #ededed;
  position: fixed;
  max-height: 700px;
  overflow-y: auto;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}
.sidebar-2020.active {
  width: 235px;
  display: block;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}
.sidebar-2020 > ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.sidebar-2020 > ul > li:last-child .dropdown-menu-2020 {
  margin-bottom: 100px;
}
.sidebar-2020 > ul > li {
  min-height: 69px;
  list-style: none;
  border-bottom: 1px dotted #ccc;
  width: 235px !important;
  overflow: hidden;
}
.sidebar-2020 > ul > li:hover {
  color: #40aefd;
}
.sidebar-2020 > ul > li > a {
  box-sizing: border-box;
  padding: 12px;
  float: left;
  width: 100%;
  color: #666;
  text-align: center;
}
.sidebar-2020 > ul > li > a:hover {
  color: #40aefd;
}
.sidebar-2020 > ul > li:hover {
  background-color: #f5f5f5;
}
.sidebar-2020 > ul > li.active {
  background-color: #f5f5f5;
}
.sidebar-2020 > ul > li.active a {
  color: #40aefd;
}
.sidebar-2020 > ul > li i.fa {
  padding: 13px;
  float: left;
  background-position: center center;
  width: 42px;
  height: 42px;
  display: inline-block;
  line-height: 14px;
  vertical-align: text-top;
  background-repeat: no-repeat;
}
.sidebar-2020 > ul > li:hover span.title {
  color: #40aefd;
}
.sidebar-2020 > ul > li i.fa {
  color: #555;
}
.sidebar-2020 > ul > li.active i.fa {
  color: inherit;
}
.sidebar-2020 > ul > li:hover i.fa {
  color: inherit;
}
.sidebar-2020 > ul > li span.title {
  float: left;
  font-weight: bold;
  padding: 10px 0px;
  opacity: 0;
  margin-left: 20px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}
.sidebar-2020.active > ul > li span.title {
  display: block;
  margin-left: 0;
  opacity: 10;
}
.sidebar-2020 ul > .dropdown > a:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;

  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(-30deg);
}
.sidebar-2020 ul > .dropdown > a.active:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;

  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(30deg);
}
.dropdown-menu-2020 {
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
  display: none;
  overflow: hidden;
}
.dropdown-menu-2020 > li {
  min-height: 30px !important;
  padding: 0;
  list-style: none;
  display: flow-root;
}
.dropdown-menu-2020 > li:hover {
  color: #40aefd !important;
}
.dropdown-menu-2020 > li:last-child {
  border-bottom: none;
}
.dropdown-menu-2020 > li > a {
  box-sizing: border-box !important;
  text-align: left !important;
  padding: 5px !important;
  padding-left: 52px !important;
  color: #777 !important;
  background-color: transparent;
  float: left;
  width: 100%;
  min-height: inherit;
}
.dropdown-menu-2020 > li > a:hover {
  background-color: #ededed !important;
  text-decoration: none;
  color: #40aefd !important;
}
.dropdown-menu-2020 .sub-menu-2020 {
  display: none;
  float: left;
  width: 100%;
  margin-left: 0px;
}
.dropdown-menu-2020 .sub-menu-2020 > li {
  list-style: none !important;
  float: left;
  width: 100%;
}
.dropdown-menu-2020 .sub-menu-2020 > li > a {
  padding: 5px !important;
  margin-left: 62px !important;
  box-sizing: border-box;
  float: left;
  width: 100%;
  color: #999;
  border-bottom: 1px dotted #ccc;
}
.dropdown-menu-2020 .sub-menu-2020 > li:last-child > a {
  border-bottom: none;
}
.dropdown-menu-2020 .sub-menu-2020 > li > a:hover {
  text-decoration: none;
  color: #40aefd;
}
main {
  /*margin-left: 70px;*/
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}
.ml240 {
  margin-left: 240px;
}
.text-primary {
  color: #111 !important;
}
.icon-primary {
  color: #40aefd;
}
.btn-primary {
  background-color: #40aefd !important;
}
.border-style-hidden {
  border-style: hidden !important;
}
.table-responsive > .table-bordered.border-top {
  border-top: 1px solid black !important;
}
.fnt16 {
  font-size: 16px !important;
}
.fnt14 {
  font-size: 14px !important;
}
.fnt12 {
  font-size: 12px !important;
}
.border-primary {
  border: 1px solid #40aefd !important;
}
.ln-ht-16 {
  line-height: 16px;
}
.expand-link {
  cursor: pointer;
}
.expand-link.active i {
  transform: rotate(180deg);
  top: 11px !important;
}
.dropdown-toggle img {
  width: 32px;
}
.dropdown-toggle.caret-mt20:after {
  margin-top: 20px;
}
.brdr-dashed {
  // border-style: dashed !important;
}
.brdr-default {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.has-icon > i {
  position: absolute;
  right: 5px;
  top: 5px;
}
.transparent-input {
  border: 0;
  background: transparent;
  width: 100%;
}
input:read-only:focus {
  outline: none;
}
.wh20 {
  width: 20px;
  height: 20px;
}
.w33 {
  width: 33.3%;
}
.fnt-size-inherit {
  font-size: inherit !important;
}
.ml45 {
  margin-left: 45px;
}
.mtb-0-5-rem {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.ltr-spc-0-8-rem {
  letter-spacing: 0.8rem;
}
.ltr-spc-0-3-rem {
  letter-spacing: 0.3rem;
}
.mt60 {
  margin-top: 60px;
}
.btn-radius-9 {
  border-radius: 0.9rem;
}
.btn-outline-primary {
  color: #40aefd;
  border: 1px solid #40aefd;
}
.btn-primary {
  border: 1px solid #40aefd;
}
.accordion > .card {
  margin-bottom: 1rem;
}
.accordion > .card:last-child {
  margin-bottom: 0;
}
.table-primary > th {
  background-color: #40aefd !important;
}
.custom-table th,
.custom-table td {
  padding: 0.5rem !important;
}
.small .form-control {
  font-size: inherit;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + -3px) !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f9fa !important;
  opacity: 1;
}
.pdf-section {
  position: fixed;
  margin-right: 15px;
}
.pdf-close {
  position: absolute;
  top: -4px;
  right: 2px;
  display: none;
  z-index: 1;
}
.pdf-close:focus {
  outline: none;
}
#labels ul {
  margin: 0;
  padding: 0;
}
#labels li {
  list-style: none;
  background-color: #40aefd;
  padding: 5px;
  text-align: center;
  color: white;
  width: auto;
  float: left;
  margin: 2px;
  font-size: 80%;
  cursor: pointer;
}
#labels li:hover {
  opacity: 0.9;
}
.timeline .row {
  margin-bottom: 80px;
}
.timeline .row:last-child {
  margin-bottom: 30px;
}
.timeline .verticle-line:after {
  content: "";
  height: 50px;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  position: absolute;
  top: 100px;
}
.timeline .row:last-child .verticle-line:after {
  content: unset;
}
.legend-link:before {
  content: "";
  position: absolute;
  left: -55px;
  top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  width: 50px;
}
.legend-link:after {
  content: "";
  position: absolute;
  left: 60px;
  top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  width: 50px;
}
.accordion > .card {
  overflow: inherit;
}
.accordion .form-control {
  height: auto;
}
.border-2 {
  border-width: 2px !important;
}
.bg-primary {
  background-color: #40aefd !important;
}
.pd-25-75-rem th,
.pd-25-75-rem td {
  padding: 0.25rem 0.75rem;
}
.status-circle {
  width: 20px;
  height: 20px;
}
.tbl-brdr-t-0 td {
  border-top: 0;
}
.col-brdr td {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.col-brdr td:last-child {
  border: 0;
}
.duplicate-inr-tbl td {
  border: 0;
  padding: 0rem 0.2rem;
}
.duplicate-inr-tbl tr.tbl-yellow {
  background-color: #ffbd28;
}
tr.tbl-yellow td {
  color: white !important;
}
.loader-container {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1030;
  text-align: center;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.loader-timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.vertical-scroll {
  overflow-y: auto;
  max-height: 500px;
}
.tbody-brdr {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.125);
}
.brdr-1 {
  border: 1px !important;
}
.w50 {
  width: 50%;
}
// .row-disabled {
//   border: 1px solid grey;
//   color: #888;
// }
// .row-disabled .text-muted {
//   color: #888 !important;
// }
// .row-disabled .input-group {
//   cursor: not-allowed;
// }
// .row-disabled select:disabled,
// .row-disabled input:read-only {
//   border: 1px solid grey !important;
//   cursor: not-allowed;
//   color: #ccc !important;
// }

.app {
  height: 100vh;
}

.transparent-borderless {
  border: 0;
  background: transparent;
}
.label-header {
  font-size: 10px;
}
.input-section {
  border: 1px solid grey;
  padding: 8px 6px !important;
  margin: 0px !important;
  min-height: 38px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  min-width: 100%;
  text-align: left !important;
  background-color: none !important;
}
.red-border {
  border-color: red;
}
.circle-block {
  width: 15px;
  height: 15px;
}
.staric-data {
  color: #d00 !important;
  font-size: 18px;
  vertical-align: -webkit-baseline-middle;
}

button {
  outline: none !important;
}

.alter-plus {
  border: 1px solid #40aefd;
  cursor: pointer;
  font-size: 10px;
  margin-left: 4px;
  padding: 3px 5px;
  border-radius: 22px;
}

.export-csv {
  padding: 0px 6px !important;
}

.text-block {
  font-size: 18px;
  font-weight: 500;
  color: #40aefd;
}
.padding-left{
  padding: 0px 0px 0px 6px;
}
.pagination-block {
  float: right;
}

.default-font-size{
  font-size: 10px;
}
