.css-26l3qy-menu {
  z-index: 999999 !important;
}
.select-block {
  width: 100%;
}

.css-yk16xz-control {
  max-height: 1px;
}
.error-border {
  border: 1px solid red;
}
