.timer-block {
    // margin: 12px 0px;
    margin-top: 60px;
    .timer-action {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .pause {
        cursor: pointer;
        .pause-icon {
          font-size: 26px;
          color: yellowgreen;
        }
      }
    }
  
  }
  .drop{
    display: flex;
    min-width: 45%
  }
.buttons{
  display: flex;
  min-width: 40%;
}
.downlist{
  min-width: 50%;
}
.outer{  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}