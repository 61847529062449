.gap-comment-box{
    margin-top: 20px;
}
.gap-comment{
    padding: 0px 16px;
    color: red;
    font-size: 12px;
}
.gap-footer{
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
}
.spinner-block{
    width: 18px;
    height: 18px;
  }