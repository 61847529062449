.doc-type-block {
  width: 100%;
  .doc-name {
    padding: 9px !important;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
