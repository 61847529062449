input[type="file"] {
  display: none;
}
.custom-file-upload {
  //   border: 1px solid #ccc;
  //   display: inline-block;
  //   padding: 6px 12px;
  //   cursor: pointer;
}

.file-input-text {
  border: 1px solid #007bff;
  padding: 4px;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
}
.file-text-disable {
  cursor: default;
  background: grey;
}
