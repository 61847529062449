.gap-check-block {
  width: 100%;
  height: 488px;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 3px;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .block-section {
    display: flex;
    justify-content: space-between;
    flex: auto;
    .year {
      position: relative;
      top: -23px;
      left: -5px;
      font-size: 11px;
    }
    .year_1 {
      position: relative;
      top: 11px;
      left: -30px;
      font-size: 11px;
    }
    .gap-check-type {
      position: relative;
      margin: 0px 7px;
    }
    .gap-check-type-section {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border-color: grey;
      background-color: grey;
      border: 2px solid grey;
      position: relative;
      top: 8px;
    }
    .big {
      top: 2px;
      width: 25px;
      height: 25px;
    }
  }
}

.gap-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px;
  border: 1px solid #d4cece;
  border-radius: 8px;
  background: #d4cece;
  margin: 8px 0px;
}

.raise-gap {
  border: none !important;
  border-color: white !important;
}

.address-line {
  .line {
    height: 36px;
    border-left: 1px solid red;
    position: absolute;
    top: 17px;
  }
  .address-location {
    position: absolute;
    top: 53px;
    font-size: 12px;
    letter-spacing: 1px;
    color: grey;
    min-width: 98px;
    text-align: center;
    left: -4px;
    text-align: left;
    font-weight: bold;
    color: darkcyan;
  }
  .address-circle {
    position: absolute;
    top: 48px;
    left: -3px;
    font-size: 8px;
    border-color: red;
    color: red;
  }
}

.address-line-up {
  .line-up {
    height: 36px;
    border-left: 1px solid blue;
    position: absolute;
    top: -22px;
  }
  .address-location-up {
    position: absolute;
    top: -42px;
    font-size: 12px;
    letter-spacing: 1px;
    color: grey;
    min-width: 98px;
    text-align: center;
    left: -7px;
    text-align: left;
    font-weight: bold;
    color: darkcyan;
  }
  .address-circle-up {
    position: absolute;
    top: -30px;
    left: -3px;
    font-size: 8px;
    border-color: blue;
    color: blue;
  }
}

.gap-block {
  margin-bottom: 75px;
}

.forword-arrow {
  font-size: 12px;
  margin: 0px 3px;
}

.red-address-line-container {
  position: absolute;
  top: 50px;
  left: 0;

  border: 1px solid blue($color: #000000);
  display: flex;
  justify-content: center;
  align-items: center;

  .red-address-line {
    border: 1px solid #ff2c2c;
    height: 0;
    flex-grow: 1;
  }

  .redline-circle {
    font-size: 8px;
    border-color: ff2c2c;
    color: ff2c2c;
  }

  .redline-marker {
    font-size: 12px;
    border-color: ff2c2c;
    color: ff2c2c;
    margin: 0 5px;
  }
}

.blue-address-line-container {
  position: absolute;
  top: -45px;
  left: 0;

  border: 1px solid blue($color: #000000);
  display: flex;
  justify-content: center;
  align-items: center;

  .blue-address-line {
    border: 1px solid #1477e9;
    height: 0;
    flex-grow: 1;
  }

  .blueline-circle {
    font-size: 8px;
    border-color: #1477e9;
    color: #1477e9;
  }

  .blueline-marker {
    font-size: 12px;
    border-color: #1477e9;
    color: #1477e9;
    margin: 0 5px;
  }
}

.gapcheck-line-info-text {
  font-size: 12px;
  margin: 0.5rem;
}
