.dashboard-block {
  margin: 7px 15px 9px 15px;
  margin-top: 60px;
  .search-section {
    margin-top: 10px;
  }
  .dashboard-upper {
    position: sticky;
    top: 60px;
    z-index: 10;
    background: #ece8e8;
    padding: 7px;
    border-radius: 4px;
    .cse-dashboard-header-block {
      display: flex;
      .cse-csis-upload {
        margin-left: 5px;
      }
    }
  }
  .filter-block {
    margin-top: 10px;
  }
  .no-data {
    text-align: center;
    margin: 60px;
    font-size: 29px;
    color: red;
  }

  .pointer {
    cursor: pointer;
  }
  .table-block {
    margin-top: 11px;
  }
  .loading-block {
    text-align: center;
    margin-top: 10px;
  }

  .csis-block-action {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin: 7px 8px;
    width: 100%;
    cursor: pointer;
  }
}
