.dashboard-block {
    margin: 7px 15px 9px 15px;
    margin-top: 60px;
  
    .search-section {
      margin-top: 10px;
    }
    .filter-section-block {
      padding: 4px 0px;
    }
    .dashboard-upper {
      position: sticky;
      top: 60px;
      z-index: 10;
      background: #ece8e8;
      padding: 7px;
      border-radius: 4px;
    }
    .filter-block {
      margin-top: 10px;
    }
    .no-data {
      text-align: center;
      margin: 60px;
      font-size: 29px;
      color: red;
    }
  
    .pointer {
      cursor: pointer;
    }
    .table-block {
      margin-top: 11px;
    }
    .loading-block {
      text-align: center;
      margin-top: 10px;
    }
    .download {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      margin: 7px 18px;
      width: 100%;
      cursor: pointer;
    }
  
    .min-height-bucket {
      min-height: 108px;
    }
    .upload-button-cases {
      padding: 2px;
      font-size: 13px;
      margin: 0px 7px;
    }
  
  }
  