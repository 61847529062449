.user-block{
    margin-top: 10px;
    overflow: hidden;
  .select-section{
    width: 100%;
  }
  .selected-user{
      margin-top: 12px;
      font-size: 14px;
      .head-section{
          font-weight: 600;
      }

  }
  .footer-section-block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 45px 0px;
  }
}