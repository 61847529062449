@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:700);
@import "../../colors";
body,
html {
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form .register-form {
  display: none;
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}
.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  color: #007bff;
  background-color: #f1f1f1;
}
.sidebar-company-name{
  color: $primary-blue;
  font-size : 85%;
  font-family : "Lucida Console", "Courier New", monospace;
}
.sidebar-roll-name{
  font-size : 70%
}