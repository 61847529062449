.client-box{
    margin: 48px 30px;
    .client-role-section{
        display: flex;
        justify-content: space-between;
        .client-section{
            width: 45%;
        }
        .role-section{
            width: 45%;
        }
    }
    .client-button-section{
      margin: 16px;
      text-align: center;
    }
}

body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

.costuminput, select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
.background-img{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.7);
  z-index: 10;
  background-image: url("/background.jpg");
}
.container-box {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px!important;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: -3px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  
  
  margin: 100px auto;

}