.action-check-block{
    .ars-number-block{
        font-size: 16px;
        font-weight: 600;
        padding: 8px 0px;
    }
    margin: 72px;
    .tab-section{
        margin: 0px;
    }
}