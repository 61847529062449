.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 140px;

  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.qcdashboard-container {
  margin-top: 60px;
}

.dashboard-upper {
  position: sticky;
  top: 60px;
  z-index: 10;
  background: #ece8e8;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.cards-container {
  position: sticky;
  top: 60px;
  z-index: 10;
  background: #ece8e8;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-flex-start;

  .cases-count-card {
    margin-right: 30px;
  }
  .cases-count-card:last-child {
    margin-right: 0px;
  }
}
