.react-datepicker__input-container {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #40aefd !important;
      opacity: 1; /* Firefox */
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #40aefd !important;
    }
  
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #40aefd !important;
    }
  }
  
  .custom-input-text {
    //  text-transform: capitalize;
  }
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }